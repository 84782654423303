.section-indicadores {
  // Pagina
  &.page-indicadores {
    .page-header {
      border: none;
      color: @color-block-title;
      font-size: @font-size-h2;
      font-family: @font-family-montserrat;
      font-weight: bold;
      padding-bottom: 0;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .view {
      .view-header {
        margin-bottom: @grid-gutter-width;
      }

      .view-filters {
        border-top: 10px solid @bg-color-section-title-indicators;
      }

      .view-content {
        &:extend(.row);

        .item {

          .item-wrapper {
            border-bottom: 1px solid @border-color-section-indicators;
            padding-bottom: 25px;
            height: 100%;

            .views-field-field-image img {
              border: 3px solid @border-color-section-indicators;
            }
          }
        }
      }
    }
  }

  .node {
    .field-name-field-tipo-indicador {
      border-bottom: 10px solid @bg-color-section-title-indicators;
      margin-bottom: 20px;
      padding-bottom: 15px;

      .field-item {
        color: @color-block-title;
        font-size: @font-size-h2;
        font-family: @font-family-montserrat;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .field-name-field-descargables {
      margin-bottom: 0;
    }

    .sharethis-buttons {
      border: none;
    }
  }
}

.aanimada-indicators-cn-wrapper {
  border-top: 10px solid @bg-color-section-title-indicators;
  padding-top: 20px;

  .aanimada-indicators-cn-filters,
  .aanimada-indicators-cn-indicators-filters,
  .aanimada-indicators-graph-cn-widget-filters {
    margin-bottom: 15px;

    label {
      font-weight: normal;
    }
  }

  .aanimada-indicators-graph-cn-widget-filters {
    label,
    .btn-group {
      display: block;
    }

    .multiselect {
      .text-left;
      float: none;
      width: 100%;

      .caret {
        position: absolute;
        margin-top: -2px;
        top: 50%;
        right: 5px;
      }
    }

    .multiselect-container {
      border: none;
      margin-top: 0;
      padding: 0;
      right: 0;

      > li {
        a {
          padding-top: 10px;
          padding-bottom: 10px;

          label {
            padding: 0;
            padding-left: 10px;
          }
        }
      }
    }

    .aanimada-indicators-graph-cn-widget-filters-apply {
      margin-bottom: 15px;
      width: 100%;

      @media (min-width: @screen-sm-min) {
        margin-top: 25px;
      }
    }
  }

  .aanimada-indicators-graph-cn-widget {
    padding: 15px 0;

    .highcharts-container {
      border: 1px solid @border-color-section-indicators-highcharts;
    }
  }

  .aanimada-indicators-graph-cn-widget-wrapper {
    border-top: 2px solid @base-color;
    padding-top: 15px;
  }

  .aanimada-indicators-graph-cn-widget-wrapper-hidden {
    display: block;
    position: absolute;
    top: -100000px;
    width: 100%;
  }
}
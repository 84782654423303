// Bloque general
.block {
  margin-bottom: @grid-gutter-width;

  .block-title {
    font-family: @font-family-montserrat;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 15px;
    text-transform: uppercase;

    &,
    a {
      color: @color-block-title;
    }

    a:hover {
      color: lighten(@color-block-title, 15%);
    }
  }
}

// Bloque Footer Acerca de
.bk-footer-about {
  .footer-logo {
    margin-bottom: 20px;

    a {
      display: inline-block;
    }
  }

  .footer-info address {
    a,
    span {
      color: @color-white;
      display: block;
    }

    a:hover {
      color: darken(@color-white, 15%);
    }
  }

  .footer-social {
    margin-top: 15px;
  }
}

// Bloque Footer Twitter
.bk-footer-twitter {
  background: url('@{images-path}bg/block-twitter.png') no-repeat scroll 90% top @color-transparent;

  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    background-image: none;
  }

  .selective-tweet {
    background-color: @bg-color-twitter;
    padding: @grid-gutter-width 10px;

    .tweet-text {
      color: @color-white;
    }

    .time-ago {
      color: @color-twitter-time-ago;
      font-size: @font-size-h6;
      font-style: italic;
    }

    a {
      color: @color-white;

      &:hover {
        color: darken(@color-white, 15%);
      }
    }
  }
}

// Bloque Footer Newsletter
.bk-footer-newsletter {
  .webform-component-cc_email,
  .field-name-body {
    margin-bottom: 15px;
  }

  .form-submit {
    .btn-primary;
    padding: 10px 30px;
    text-transform: uppercase;
  }
}

// Bloque Contacto - Mapa
.bk-contact {
  .bk-contact-info {
    background-color: @bg-color-bk-contact;
    border-top: 10px solid @border-color-bk-contact;
    padding: 20px 15px;
    margin-bottom: 15px;

    address {
      margin-bottom: 0;

      a,
      span {
        display: block;
      }

      a {
        word-wrap: break-word;
      }
    }
  }

  .bk-contact-map-wrapper {
    height: 380px;

    #af-contact-map {
      height: 100%;
    }
  }
}

// Bloque titulo sección
.bk-title-section {
  margin-bottom: 0;

  h1 {
    color: @color-white;
    font-family: @font-family-montserrat;
    font-size: @font-size-title-section;
    margin-top: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  @media (max-width: @screen-xs-max) {
    h1 {
      .text-center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

// Bloque Easy Breadcrumb
.bk-easy-breadcrumb {
  margin-bottom: 0;

  .easy-breadcrumb {
    .text-right;
    margin-top: 32px;
    margin-bottom: 32px;

    a,
    span {
      color: @color-white;
    }

    a:hover {
      color: darken(@color-white, 10%);
    }
  }

  @media (max-width: @screen-xs-max) {
    .easy-breadcrumb {
      .text-center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

// Bloque tags - Blog
.bk-tags {
  .field-name-field-tags {
    background-color: @bg-color-bk-tag;
    padding: 10px 10px 15px;

    .field-item {
      background-color: @bg-color-bk-tag-item;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 10px;
      padding: 5px 10px;

      &:hover {
        background-color: lighten(@bg-color-bk-tag-item, 10%);
      }

      a {
        color: @color-white;
        font-weight: bold;
        font-size: @font-size-h6;
        text-transform: uppercase;
      }
    }
  }
}

// Bloque cloud tags - Blog
.bk-cloud-tags-blog {
  background-color: @bg-color-bk-tag;
  padding: 0 15px 25px;

  .block-title {
    background-color: @color-white;
    padding-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .tagclouds-term {
    background-color: @bg-color-bk-tag-item;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 5px 10px;

    &:hover {
      background-color: lighten(@bg-color-bk-tag-item, 10%);
    }

    a {
      color: @color-white;
      font-weight: bold;
      font-size: @font-size-h6;
      text-transform: uppercase;
    }
  }
}

// Bloque perfil bloguero - Blog
.bk-blogger-profile {
  .views-field-field-image {
    .pull-left;
    margin-right: 15px;
    margin-bottom: 15px;

    img {
      border: 1px solid @border-color-bk-blogger-profile-img;
    }
  }

  .views-field-title {
    h3 {
      color: @color-black;
      font-size: @font-size-h4;
      font-weight: 600;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }

  .views-field-field-twitter {
    margin-bottom: 15px;

    a {
      font-size: @font-size-h6;
      font-style: italic;
    }
  }

  .views-field-body {
    font-size: @font-size-h6;
  }
}

// Bloque Otros blogs | Bloque Blogs detacados - Blog
.bk-blogger-other-blogs,
.bk-blogs-featured,
.bk-recent-news {
  .views-field-title {
    h3 {
      border-bottom: 1px solid @border-color-bk-blogger-other-blogs-link;
      font-size: @font-size-base;
      margin-bottom: 0;
      margin-top: 0;
      padding: 15px 0;

      a {
        color: @text-color;

        &:hover {
          color: darken(@text-color, 10%);
        }
      }
    }
  }
}

// Bloque Blog destacados - Blog
.bk-blogs-featured {
  .view-content {
    background-color: @bg-color-bk-blogs-featured;

    .views-field-title h3 {
      padding-left: 15px;
      padding-right: 15px;
    }

    .views-row.views-row-last {
      .views-field-title h3 {
        border-bottom: none;
      }
    }
  }
}

// Bloque Nuestros socios - Home
.bk-nuestros-socios {
  clear: both;

  @media (min-width: @screen-md-min) {
    margin-top: 20px;
  }

  .block-title {
    color: @bg-color-section-title-socio;
  }

  .view-socios {
    border-top: 10px solid @bg-color-section-title-socio;
  }

  .view-content {
    &:extend(.row);
  }

  .views-row {
    margin-top: 30px;
    margin-bottom: 30px;

    .views-field-field-image {
      a {
        display: block;

        img {
          .__opacity(0.8);
          margin: 0 auto;

          &:hover {
            .__opacity(1);
          }
        }
      }
    }
  }
}

// Bloque Blog destacado - Home
.bk-blog-featured-home {
  .view-content {
    border-top: 10px solid @bg-color-section-title-blog;

    .item {
      margin-top: 0;
    }
  }
}

// Bloque Noticias Actualidad - Home
.bk-noticias-home {
  .view-content {
    border-top: 10px solid @bg-color-section-title-news;

    .item {
      margin-top: 0;
    }
  }
}

// Bloque eventos - Home
.bk-eventos-home {
  .block-title {
    background: url('@{images-path}bg/block-calendar-home.png') no-repeat scroll right center @color-transparent;

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      background: none;
    }
  }

  .view-content {
    border-top: 10px solid @bg-color-section-title-event;

    .item {
      margin-top: 0;
    }
  }
}

// Bloque politicas publicas - Home
.bk-politicas-publicas-home {
  .view-content {
    border-top: 10px solid @bg-color-section-title-public-politics;
  }

  .btn {
    text-transform: uppercase;
  }
}

// Bloque publicaciones - Home
.bk-publicaciones-home {
  .view {
    border-top: 10px solid @bg-color-section-title-publications;
    background-color: @bg-color-bk-publicaciones-home;
    min-height: 195px;
    padding: 35px 20px 20px;

    .item {
      margin-top: 0;

      .views-field-title {
        h3 {
          font-weight: bold;
          font-size: @font-size-bk-title-publications;

          a {
            color: @color-black;

            &:hover {
              color: lighten(@color-black, 30%);
            }
          }
        }
      }
    }

    .btn {
      text-transform: uppercase;
    }
  }
}

// Bloque Escuela para ciudadanos - Home
.bk-escuelas-home {

  .bk-escuelas-home-content {
    border-top: 10px solid @bg-color-section-title-school;
    background-color: @bg-color-bk-school-home;
    min-height: 195px;
    padding: 35px 20px 20px;

    h3 {
      font-size: @font-size-bk-school;
      font-weight: normal;
      margin: 0 0 15px;

      &,
      a {
        color: @color-block-title;
      }

      a:hover {
        color: darken(@color-block-title, 15%);
      }
    }
  }

  .btn {
    text-transform: uppercase;
  }
}

// Bloque menu sidebar
.bk-menu-sidebar {
  background-color: @bg-color-bk-menu-sidebar;

  ul {
    li {
      border-bottom: 1px solid @border-color-bk-menu-sidebar;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: @text-color;
        font-family: @font-family-source-sans-pro;
        padding: 15px 20px;

        &:hover {
          color: @base-color;

          .section-politicas-publicas & {
            color: @bg-color-section-title-public-politics;
          }

          .section-nosotros & {
            color: @bg-color-section-title-about;
          }

          .section-publicaciones & {
            color: @bg-color-section-title-publications;
          }

          .section-indicadores & {
            color: @bg-color-section-title-indicators;
          }
        }
      }

      > a .caret {
        .responsive-visibility();
        background: url('@{images-path}/icons/icon-chevron-right.png') no-repeat scroll 0 0 @color-transparent;
        border: none;
        height: 17px;
        margin-left: 0;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -8.5px;
        width: 9px;
      }

      &.active-trail,
      &.open {

        .dropdown-menu {
          display: block;
        }

        > a .caret {
          background: url('@{images-path}/icons/icon-chevron-down.png') no-repeat scroll 0 0 @color-transparent;
          height: 9px;
          margin-top: -4.5px;
          width: 17px;
        }
      }

      &.active-trail a.active-trail,
      &.active a.active {
        color: @color-white;

        .section-politicas-publicas & {
          background-color: @bg-color-section-title-public-politics;
        }

        .section-nosotros & {
          background-color: @bg-color-section-title-about;
        }

        .section-publicaciones & {
          background-color: @bg-color-section-title-publications;
        }

        .section-indicadores & {
          background-color: @bg-color-section-title-indicators;
        }
      }

      > .dropdown-menu {
        .__box-shadow(none);
        background-color: @bg-color-bk-menu-sidebar-submenu;
        border: none;
        float: none;
        margin-top: 0;
        padding: 0;
        position: relative;
        z-index: 1;

        li {
          border-bottom: none;

          a {
            padding-left: 25px;
            padding-right: 25px;
            white-space: normal;
          }

          &.active-trail a.active-trail,
          &.active a.active {
            background: none;

            .section-politicas-publicas & {
              color: @bg-color-section-title-public-politics;
            }

            .section-nosotros & {
              color: @bg-color-section-title-about;
            }

            .section-publicaciones & {
              color: @bg-color-section-title-publications;
            }

            .section-indicadores & {
              color: @bg-color-section-title-indicators;
            }
          }
        }
      }
    }
  }
}

//
// Banner Slide Principal
//
.banner-slider-main {
  &:extend(.banner-af all);
  ._banner-slider(true, true, true);
  overflow: hidden;

  @media (min-width: @screen-md-min) {
    margin-bottom: 60px;
  }

  @media (min-width: @screen-lg-min) {
    .banner-slider-content .col-md-4 {
      padding-left: @grid-gutter-width;
    }
  }

  .views-field img {
    max-height: 550px;
  }
}

// Bloque eventos - Sidebar
.bk-events-sidebar {
  ._banner-slider(true, false, true);

  .view-content {
    border-top: 10px solid @bg-color-section-title-event;

    .owl-item {
      &:extend(.item all);
      margin-top: 0;
    }
  }

  .owl-carousel .owl-controls .owl-pagination {
    position: relative;
    bottom: auto;
  }
}
.section-blog {
  .bk-tags-blog {
    &.bk-tags {
      .field-name-field-tags {
        border-top: 10px solid @bg-color-section-title-blog;
      }
    }
  }

  .bk-cloud-tags-blog .block-title {
    border-bottom: 10px solid @bg-color-section-title-blog;
  }

  .bk-blogger-profile .view-content,
  .bk-blogger-other-blogs .view-content,
  .bk-blogs-featured .view-content {
    border-top: 10px solid @bg-color-section-title-blog;
    padding-top: 20px;
  }

  .bk-blogs-featured .view-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .page-header {
    .responsive-invisibility();
  }

  .page-blog-content {
    margin-top: 37px;
    border-top: 10px solid @bg-color-section-title-blog;

    > .view-content {
      &:extend(.row);
    }
  }

  //Tags
  &.section-tags {
    .node-post {
      &:extend(.row);
      margin-top: 30px;

      .group-left,
      .group-right {
        float: none;
        width: 100%;
      }

      .group-left {
        .make-sm-column(3);
      }

      .group-right {
        float: none;
        .make-sm-column(9);
      }

      .group-header{
        .make-sm-column(12);

        .field-name-title {
          margin-bottom: 5px;

          h2 {
            font-size: @font-size-h4;
            font-weight: 600;
            margin: 0;

            a {
              color: @color-black;

              &:hover {
                color: lighten(@color-black, 40%);
              }
            }
          }
        }
      }

      .group-footer {
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 15px;
        border-top: 1px solid @border-color-blog;
      }
    }
  }
}

// Item blog
.blog-item {
  .item-wrapper {
    border-bottom: 1px solid @border-color-page-blog;
    padding-bottom: 25px;
    height: 100%;
  }
}
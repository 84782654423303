.section-publicaciones {
  // Pagina
  &.page-publicaciones {
    .page-header {
      border: none;
      color: @color-block-title;
      font-size: @font-size-h2;
      font-family: @font-family-montserrat;
      font-weight: bold;
      padding-bottom: 0;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .view {
      .view-header {
        margin-bottom: @grid-gutter-width;
      }

      .view-filters {
        border-top: 10px solid @bg-color-section-title-publications;
      }

      .view-content {
        &:extend(.row);

        .item {

          .item-wrapper {
            border-bottom: 1px solid @border-color-section-publications;
            padding-bottom: 25px;
            height: 100%;
          }
        }
      }
    }
  }

  .node {
    .field-name-field-tipo-de-publicacion {
      border-bottom: 10px solid @bg-color-section-title-publications;
      margin-bottom: 20px;
      padding-bottom: 15px;

      .field-item {
        color: @color-block-title;
        font-size: @font-size-h2;
        font-family: @font-family-montserrat;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .field-name-field-descargables {
      margin-bottom: 0;
    }

    .sharethis-buttons {
      border: none;
    }
  }
}
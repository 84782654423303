.section-eventos {
  .page-header {
    .responsive-invisibility();
  }

  .page-event-content {
    > .view-header {
      margin-top: @grid-gutter-width;

      #aanimada-frontend-get-calendar-event-view-date-filter {

        .calendar-events-date-filter-container {
          .make-md-column(6);

          > .form-wrapper {
            &:extend(.row);
          }

          .form-type-date-select {
            width: 100%;
            .make-sm-column(9);
            margin-bottom: 15px;
            margin-right: 0;

            #edit-event-date {
              &:extend(.row);
              float: none;

              .form-item {
                .make-xs-column(6);

                &.form-item-event-date-year {

                  @media (min-width: @screen-sm-min) {
                    padding-left: 0;
                  }
                }
              }
            }
          }

          .form-submit {
            margin: 0 auto;
            display: block;
            .make-sm-column(3);
            background-color: @bg-color-section-title-event;
            border: none;
            margin-bottom: 15px;

            &:hover {
              background-color: darken(@bg-color-section-title-event, 15%);
            }
          }
        }
      }

      .view-filters {
        background-color: @color-white;

        .views-exposed-form {
          &:extend(.row);
        }

        .views-exposed-widgets {
          .make-md-column(6);
          margin-bottom: 0;

          .views-exposed-widget {
            .make-sm-column(9);
            border-left: none;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 15px;

            .views-widget {

              @media (min-width: @screen-sm-min) {
                display: inline-block;
                margin-left: 10px;
                width: 77%;
              }

              @media (min-width: @screen-md-min) {
                width: 60%;
              }

              @media (min-width: @screen-lg-min) {
                width: 70%;
              }
            }
          }

          .views-submit-button {
            .responsive-visibility();
            .make-sm-column(3);
            margin-bottom: 15px;

            @media (min-width: @screen-sm-min) {
              padding-left: 0;
              padding-right: 0;

              .btn {
                width: 100%;
              }
            }

            .btn {
              background-color: @bg-color-section-title-event;
              border: none;
              margin: 0 auto;
              display: block;

              &:hover {
                background-color: darken(@bg-color-section-title-event, 15%);
              }

              .ajax-progress-throbber {
                .responsive-invisibility();
              }
            }
          }
        }
      }

      .panel-heading {
        background-color: @bg-color-section-title-event;
        padding: 15px;

        .panel-title {
          font-family: @font-family-montserrat;
          font-size: @font-size-h2;
          font-weight: bold;
          text-transform: uppercase;

          a {
            color: @color-white;
            display: block;
            position: relative;

            .glyphicon {
              .__border-radius(50%);
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -18px;
              border: 2px solid @color-white;
              padding: 5px;
            }
          }
        }
      }

      .panel-body {
        @media (max-width: @screen-xs-max) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    > .view-content {
      &:extend(.row);

      &-title {
        border-bottom: 10px solid @bg-color-section-title-event;
        color: @color-block-title;
        font-size: @font-size-h2;
        font-family: @font-family-montserrat;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 15px;
        text-transform: uppercase;
      }

      .item {

        .item-wrapper {
          border-bottom: 1px solid @border-color-section-publications;
          padding-bottom: 25px;
          height: 100%;
        }
      }

      > .view-footer {
        .item {
          .item-wrapper {
            background-color: @bg-color-event-outstanding;
            border-bottom: none;
            padding: 15px 10px 25px;

            .views-field-title h3 a {
              &:hover {
                color: darken(@color-white, 15%);
              }
            }

            .views-field-title h3 a,
            .views-field-field-fecha,
            .views-field-field-fecha span,
            .views-field-body {
              color: @color-white;
            }

            .views-field-field-fecha {
              text-transform: uppercase;
              font-style: normal;

              span {
                font-style: italic;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }

  &.page-eventos {
    .popover {
      border: none;
      margin-left: 15px;
      margin-right: 15px;
      max-width: 360px;
      padding: 0;

      .popover-close {
        position: absolute;
        top: -12px;
        right: -12px;

        span {
          .__border-radius(50%);
          background-color: @color-white;
          cursor: pointer;
          color: @link-color;
          font-size: 32px;
        }
      }

      .popover-content {
        padding: 0;

        .item-wrapper {
          .views-field-field-date-title,
          .views-field-body-more {
            padding: 20px 10px;
          }

          .views-field-field-date-title {
            background-color: @bg-color-section-title-event;

            .views-field-field-fecha span,
            .views-field-title h3 {
              color: @color-white;
            }

            .views-field-field-fecha {
              margin-bottom: 10px;

              span {
                font-style: italic;
                font-size: @font-size-h6;
              }
            }

            .views-field-title h3 {
              font-weight: 600;
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          .views-field-body-more {
            .views-field-body {
              margin-bottom: 25px;
            }

            .views-field-more {
              a {
                .btn;
                .btn-primary;
                padding: 10px 20px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }

  .node {
    .field-name-field-descargables {
      border-bottom: none;
    }
  }
}

.calendar-month-events {
  .view-header {
    border-top: 2px solid @bg-color-section-title-event;
    clear: both;
    padding-top: 15px;

    .date-nav-wrapper {
      margin-top: 0;
      background-color: @bg-color-header-calendar;

      .date-nav {
        border-bottom: 1px solid @color-white;
        margin-bottom: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        position: relative;
      }

      .date-heading {
        h3 {
          color: @color-white;
          font-size: @font-size-h2;
          font-weight: bold;
          line-height: 115%;
          text-transform: uppercase;
        }
      }

      .pager {
        margin: 0;

        .date-prev,
        .date-next {
          .responsive-invisibility();
          background-color: transparent;
          margin-top: -17px;
          padding: 0;
          top: 50%;

          a {
            .text-hide();
            .glyphicon;
            color: @color-white;

            &:before {
              font-size: 24px;
            }
          }
        }

        .date-prev {
          left: 0;
          right: auto;

          a {
            .glyphicon-menu-left;
          }
        }

        .date-next {
          right: 0;

          a {
            .glyphicon-menu-right;
          }
        }
      }
    }
  }

  .view-content {
    .calendar-calendar {
      .month-view {
        th.days {
          .text-left;
          background-color: @bg-color-header-calendar;
          border-color: @border-color-body-calendar;
          color: @color-white;
          font-size: @font-size-h4;
          font-weight: bold;
          padding: 10px 15px;
          line-height: 100%;
          text-transform: uppercase;

          @media (max-width: @screen-xs-max) {
            font-size: @font-size-h5;
            padding: 5px;
          }
        }

        tbody {
          tr.single-day {
            height: 134px;
            max-height: 134px;
          }

          td {
            background-color: @bg-color-body-calendar;
            border-color: @border-color-body-calendar;
            color: @color-body-calendar-num;

            a {
              color: @color-block-title;
              font-size: @font-size-calendar-month-text;
              font-weight: normal;
              text-decoration: none;

              &:hover {
                color: @color-black;
              }
            }

            &.empty {
              color: @color-body-calendar-num-empty;

              .month.day {
                font-weight: normal;
              }
            }

            .month.day {
              float: left;
              font-size: @font-size-h3;
              font-weight: bold;
            }

            &.date-box.today,
            &.single-day.today {
              background-color: @bg-color-body-calendar-active;
              border: none;
            }

            &.single-day {
              .monthview {
                .__border-radius(0);
                background-color: @color-white;
                padding: 10px 5px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}


.section-actualidad {
  .page-header {
    .responsive-invisibility();
  }

  .page-news-content {
    .view-header {
      margin-bottom: @grid-gutter-width;
    }
    
    .view-filters {
      border-top: 10px solid @bg-color-section-title-news;
    }

    .view-content {
      &:extend(.row);

      .item {

        .item-wrapper {
          border-bottom: 1px solid @border-color-section-news;
          padding-bottom: 25px;
          height: 100%;
        }
      }
    }
  }

  .bk-recent-news .view-content {
    border-top: 10px solid @bg-color-section-title-news;
    padding-top: 20px;
  }

  // Detalle
  .node {
    .field-name-field-categoria-de-noticia {
      border-bottom: 10px solid @bg-color-section-title-news;
      margin-bottom: 20px;
      padding-bottom: 15px;

      .field-item {
        color: @color-block-title;
        font-size: @font-size-h2;
        font-family: @font-family-montserrat;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .field-name-field-descargables {
      border-bottom: none;
    }
  }
}
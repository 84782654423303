.section-search {
  .page-header {
    .responsive-invisibility();
  }

  .search-results {
    padding-left: 15px;

    .search-result {
      padding-bottom: 15px;
      border-bottom: 1px solid @border-color-search;
    }
  }
}

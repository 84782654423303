.section-socios {

  // pagina vista
  &.page-nosotros-socios {
    .page-header {
      border-bottom: 10px solid @bg-color-section-title-socio;
      color: @color-block-title;
      font-size: @font-size-h2;
      font-family: @font-family-montserrat;
      font-weight: bold;
      padding-bottom: 15px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }

  .node {
    .field-name-subtitulo-socio {
      border-bottom: 10px solid @bg-color-section-title-socio;
      margin-bottom: 20px;
      padding-bottom: 15px;

      .field-item {
        color: @color-block-title;
        font-size: @font-size-h2;
        font-family: @font-family-montserrat;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .field-name-field-image {
      float: left;
      margin-right: @grid-gutter-width;
    }
  }
}
.section-salud-para-ciudadanos {
  .page-header {
    .responsive-invisibility();
  }

  .page-school-content {
    .view-header {
      margin-bottom: @grid-gutter-width;
    }

    .view-filters {
      border-top: 10px solid @bg-color-section-title-school;
    }

    .view-content {
      &:extend(.row);

      .item {

        .item-wrapper {
          border-bottom: 1px solid @border-color-section-school;
          padding-bottom: 25px;
          height: 100%;
        }
      }
    }
  }
}
.section-contacto {

  .page-header {
    .responsive-invisibility();
  }

  .block-system .node-webform {
    margin-top: 37px;

    @media (max-width: @screen-xs-max) {
      margin-top: 0;
    }

    .webform-client-form {
      &:extend(.row);

      label {
        font-weight: normal;
      }

      .form-actions {
        .make-sm-column(12);
        margin-bottom: 20px;

        .form-submit {
          .make-sm-column(2);
        }
      }
    }
  }
}
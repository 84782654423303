//
// ScrollUp
//
#scrollUp {
  .glyphicon;
  .glyphicon-chevron-up;
  .__opacity(0.6);

  background-color: @base-color;
  color: @color-white;
  bottom: 20px;
  padding: 10px;
  right: 20px;
  top: auto;

  &:hover {
    .__opacity(1);
  }
}

//
// Banner principal - Home
//
.banner-af {
  ._banner();
}

//
// Icons
//
.aicon {
  .__opacity(0.8);
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    .__opacity(1);
  }

  a {
    .text-hide;
    display: block;
    height: 51px;
    width: 51px;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &-small {
    margin-right: 0;

    a {
      height: 33px;
      width: 33px;
    }
  }

  &-small-facebook {
    background-image: url('@{images-path}icons/icon-small-facebook.png');
  }

  &-small-twitter {
    background-image: url('@{images-path}icons/icon-small-twitter.png');
  }

  &-small-youtube {
    background-image: url('@{images-path}icons/icon-small-youtube.png');
  }

  &-small-email {
    background-image: url('@{images-path}icons/icon-small-email.png');
  }

  &-facebook {
    background-image: url('@{images-path}icons/icon-facebook.png');
  }

  &-twitter {
    background-image: url('@{images-path}icons/icon-twitter.png');
  }

  &-youtube {
    background-image: url('@{images-path}icons/icon-youtube.png');
  }

  &-email {
    background-image: url('@{images-path}icons/icon-email.png');
  }
}

//
// Sharethis
//
.sharethis-buttons {
  clear: both;
  border-top: 1px solid @border-color-sharethis;
  padding-top: 20px;
  padding-bottom: 20px;
}

//
// Región navegación
//
.navigation {
  background-color: @base-color;
  margin-bottom: @grid-gutter-width;

  .region-navigation {
    &:extend(.row);
  }

  //Sección Contacto
  .section-contacto & {
    background-color: @bg-color-section-title-contact;
  }

  //Sección blog
  .section-blog & {
    background-color: @bg-color-section-title-blog;
  }

  .section-indicadores & {
    background-color: @bg-color-section-title-indicators;
  }

  .section-publicaciones & {
    background-color: @bg-color-section-title-publications;
  }

  .section-politicas-publicas & {
    background-color: @bg-color-section-title-public-politics;
  }

  .section-actualidad & {
    background-color: @bg-color-section-title-news;
  }

  .section-nosotros & {
    background-color: @bg-color-section-title-about;
  }

  .section-eventos & {
    background-color: @bg-color-section-title-event;
  }

  .section-salud-para-ciudadanos & {
    background-color: @bg-color-section-title-school;
  }
}

//
// Home
//
.front {

  .site-main-content .region-content,
  .content-second .region-content-second {
    &:extend(.row);
  }
}

//
// Campos
//
.field-name-field-fecha {
  color: @link-color;
  font-size: @font-size-h6;
  font-style: italic;
  margin-bottom: 15px;
}

.page-header,
.field-name-title h1 {
  color: @color-black;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: @grid-gutter-width;
}

.field-name-field-image,
.field-name-field-imagen2 {
  margin-bottom: @grid-gutter-width;

  img {
    margin: 0 auto;
  }

  ~ .field-name-field-referencia-foto {
    margin-top: -20px;
  }
}

.field-name-field-referencia-foto {
  font-style: italic;
  font-size: @font-size-h6;
  margin-bottom: @grid-gutter-width;
}

.field-name-body {
  margin-bottom: @grid-gutter-width;
}

.comment-wrapper {
  .comment-form {
    margin-bottom: 40px;

    &.title {
      margin-bottom: 10px;
      margin-top: 0;
    }

    label {
      font-weight: normal;
    }
  }

  .comment {
    .submitted {
      > span {
        color: @link-color;
        text-transform: capitalize;
        font-style: italic;
        font-size: @font-size-h6;

        .username {
          color: @gray-dark;
          font-weight: 600;
          font-style: normal;
          font-size: @font-size-base;
          margin-right: 5px;
        }
      }
    }

    .field-name-comment-body {
      &,
      p {
        color: @gray-dark !important;
      }
    }
  }
}

.captcha {
  &.form-wrapper {
    .panel-body,
    .g-recaptcha {
      .text-center;
    }

    .g-recaptcha {
      margin: 0 auto;
      display: inline-block;
    }
  }
}

.field-name-field-twitter {
  margin-bottom: 15px;

  a {
    font-size: @font-size-h6;
    font-style: italic;
  }
}

.field-name-field-descargables {
  border-bottom: 1px solid @border-color-file;
  margin-bottom: 15px;
  padding-bottom: 10px;

  .file {
    display: block;
    font-style: italic;
    margin-top: 10px;

    a {
      margin-left: 5px;
    }

    &-size {
      .responsive-invisibility();
    }
  }
}

.field-name-field-url {
  margin-bottom: 15px;

  a {
    font-size: @font-size-h4;
    font-style: italic;
  }
}

//
// Paginador
//
.pagination {
  margin: 30px 15px;

  > li {

    a,
    span {
      margin-right: 10px;
      margin-bottom: 10px;
      text-transform: capitalize;
    }
  }
}

// Item base
.item {
  margin-top: 25px;

  .item-wrapper {

    .views-field-field-image,
    .views-field-field-fecha-autor,
    .views-field-field-fecha,
    .views-field-field-tag,
    .views-field-title,
    .views-field-body {
      margin-bottom: 15px;
    }

    .views-field-field-image {
      a {
        display: block;
      }
    }

    .views-field-field-fecha,
    .views-field-field-fecha-autor {
      &,
      span {
        color: @link-color;
        font-style: italic;
        font-size: @font-size-h6;
      }
    }

    .views-field-field-tag {
      color: @link-color;
      text-transform: uppercase;
      font-size: @font-size-h6;
    }

    .views-field-title {
      h3 {
        font-size: @font-size-h4;
        font-weight: 600;
        margin: 0;

        a {
          color: @color-black;

          &:hover {
            color: lighten(@color-black, 40%);
          }
        }
      }
    }
  }
}

//
// Filtros - Vistas
//
.view-filters {
  background-color: @bg-color-filters;

  label {
    font-weight: normal;
    font-size: @font-size-filter-label;
  }

  .views-exposed-widgets {
    .views-exposed-widget {
      float: none;
      .make-sm-column(6);
      border-left: 3px solid @border-color-filters;
      padding-top: 15px;
      padding-bottom: 15px;

      &:first-child {
        border-left: none;
      }

      @media (max-width: @screen-xs-max) {
        border-left: none;
      }
    }

    .views-submit-button {
      .responsive-invisibility();
    }

    .views-widget-filter-title .views-widget input {
      background: url('@{images-path}icons/icon-search.png') no-repeat scroll 98% center @color-white;
      padding-right: 30px;
    }

    .views-widget-filter-field_fecha_value .views-widget {
      &:extend(.row);

      > div {
        .make-sm-column(6);

        .container-inline-date > .form-item {
          display: block;
          margin-right: 0;
        }

        .date-padding {
          &,
          .form-item {
            float: none;
          }

          .form-item input {
            background: url('@{images-path}icons/icon-calendar.png') no-repeat scroll 96% center @color-white;
            padding-right: 30px;
            width: 100%;
          }
        }

        .help-block {
          .responsive-invisibility();
        }
      }
    }
  }
}

.field-name-field-file-indicador {
  #aanimada-indicators-graph {
    padding: 15px;

    .highcharts-container {
      border: 1px solid @border-color-section-indicators-highcharts;
    }
  }
}

.field-name-field-file-indicador-mapa {

  .aanimada-indicators-graph-map-widget-filters {
    label {
      font-weight: normal;
    }

    label,
    .btn-group {
      display: block;
    }

    .multiselect {
      .text-left;
      float: none;
      width: 100%;

      .caret {
        position: absolute;
        margin-top: -2px;
        top: 50%;
        right: 5px;
      }
    }

    .multiselect-container {
      border: none;
      margin-top: 0;
      padding: 0;
      right: 0;

      > li {
        a {
          padding-top: 10px;
          padding-bottom: 10px;

          label {
            padding: 0;
            padding-left: 10px;
          }
        }
      }
    }

    .aanimada-indicators-graph-map-widget-apply {
      margin-bottom: 15px;
      width: 100%;

      @media (min-width: @screen-sm-min) {
        margin-top: 25px;
      }
    }
  }

  .aanimada-indicators-graph-map {
    padding: 15px 0;

    .highcharts-container {
      border: 1px solid @border-color-section-indicators-highcharts;
    }
  }

  .aanimada-indicators-graph-map-filters {
    border-bottom: 2px solid @base-color;
    margin-bottom: 15px;
    padding-bottom: 15px;

    > div {
      padding-left: 0;
      padding-right: 0;
    }

    label {
      font-weight: normal;
    }
  }

  .aanimada-indicators-graph-map-widget-wrapper-hidden {
    display: block;
    position: absolute;
    top: -100000px;
    width: 100%;
  }

  .aanimada-indicators-graph-map-widget {
    padding: 15px 0;

    .highcharts-container {
      border: 1px solid @border-color-section-indicators-highcharts;
    }
  }
}

.field-name-field-ficha-tecnica {
  table {
    .table;
    .table-hover;
    .table-striped;
    width: 100% !important;
  }
}

.field-name-field-tabla {
  table {
    th,
    td {
      .text-center;
    }
  }
}

//
// Tabs horizontales
//
.field-group-htabs-wrapper {
  .horizontal-tabs {
    border: none;
    margin-bottom: 0;

    .horizontal-tabs-list {
      background-color: @bg-color-tabs;
      border-right: none;

      .horizontal-tab-button {
        .text-center;
        border-right: none;
        padding: 0;

        @media (max-width: @screen-sm-max) {
          width: 33.3%;
        }

        @media (max-width: @screen-xs-max) {
          min-height: 68px;
        }

        a {
          font-size: @font-size-h6;
          padding: 17px 22px;
          text-transform: uppercase;

          &:hover {
            background-color: @color-transparent;
          }

          strong {
            color: @color-black;
            font-weight: normal;
          }
        }

        &:hover,
        &.selected {
          background-color: @bg-color-tab-active;
          padding-bottom: 0;

          a strong {
            color: @color-white;
          }
        }
      }
    }

    .horizontal-tabs-panes {
      .horizontal-tabs-pane {
        .__box-shadow(none);
        padding: 25px 0;
        margin-bottom: 0;

        .panel-body {
          padding: 0;
        }
      }
    }
  }
}
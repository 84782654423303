/* This stylesheet generated by Transfonter (http://transfonter.org) on June 29, 2016 2:36 AM */

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-ExtraLightIt.eot');
  src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightIt'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLightIt.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLightIt.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLightIt.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLightIt.svg#SourceSansPro-ExtraLightIt') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-BlackIt.eot');
  src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackIt'),
  url('@{fonts-path}hinted-SourceSansPro-BlackIt.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-BlackIt.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-BlackIt.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-BlackIt.svg#SourceSansPro-BlackIt') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-Black.eot');
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
  url('@{fonts-path}hinted-SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-Black.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-Black.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-Semibold.eot');
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
  url('@{fonts-path}hinted-OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-Semibold.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-Semibold.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans.eot');
  src: local('Open Sans'), local('OpenSans'),
  url('@{fonts-path}hinted-OpenSans.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('@{fonts-path}hinted-OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-BoldItalic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-BoldItalic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('@{fonts-path}hinted-OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-Italic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-Italic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-SemiboldIt.eot');
  src: local('Source Sans Pro Semibold Italic'), local('SourceSansPro-SemiboldIt'),
  url('@{fonts-path}hinted-SourceSansPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-SemiboldIt.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-SemiboldIt.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-SemiboldIt.svg#SourceSansPro-SemiboldIt') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-ExtraboldItalic.eot');
  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'),
  url('@{fonts-path}hinted-OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-ExtraboldItalic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-ExtraboldItalic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-Semibold.eot');
  src: local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'),
  url('@{fonts-path}hinted-SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-Semibold.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-Semibold.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-Semibold.svg#SourceSansPro-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@{fonts-path}hinted-Montserrat-Bold.eot');
  src: local('Montserrat-Bold'),
  url('@{fonts-path}hinted-Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-Montserrat-Bold.woff') format('woff'),
  url('@{fonts-path}hinted-Montserrat-Bold.ttf') format('truetype'),
  url('@{fonts-path}hinted-Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-ExtraLight.eot');
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLight.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLight.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('@{fonts-path}hinted-OpenSans-CondensedBold.eot');
  src: local('Open Sans Condensed Bold'), local('OpenSans-CondensedBold'),
  url('@{fonts-path}hinted-OpenSans-CondensedBold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-CondensedBold.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-CondensedBold.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-CondensedBold.svg#OpenSans-CondensedBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-Regular.eot');
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'),
  url('@{fonts-path}hinted-SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-Regular.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-Regular.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-Light.eot');
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
  url('@{fonts-path}hinted-SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-Light.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-Light.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-Extrabold.eot');
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'),
  url('@{fonts-path}hinted-OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-Extrabold.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-Extrabold.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-Bold.eot');
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
  url('@{fonts-path}hinted-SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-Bold.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-Bold.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('@{fonts-path}hinted-OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-Light.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-Light.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('@{fonts-path}hinted-OpenSans-CondensedLight.eot');
  src: local('Open Sans Condensed Light'), local('OpenSans-CondensedLight'),
  url('@{fonts-path}hinted-OpenSans-CondensedLight.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-CondensedLight.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-CondensedLight.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-CondensedLight.svg#OpenSans-CondensedLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-SemiboldItalic.eot');
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'),
  url('@{fonts-path}hinted-OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-SemiboldItalic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-SemiboldItalic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-It.eot');
  src: local('Source Sans Pro Italic'), local('SourceSansPro-It'),
  url('@{fonts-path}hinted-SourceSansPro-It.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-It.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-It.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-It.svg#SourceSansPro-It') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSansLight-Italic.eot');
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url('@{fonts-path}hinted-OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSansLight-Italic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSansLight-Italic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{fonts-path}hinted-OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('@{fonts-path}hinted-OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-Bold.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-Bold.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Condensed';
  src: url('@{fonts-path}hinted-OpenSans-CondensedLightItalic.eot');
  src: local('Open Sans Condensed Light Italic'), local('OpenSans-CondensedLightItalic'),
  url('@{fonts-path}hinted-OpenSans-CondensedLightItalic.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-OpenSans-CondensedLightItalic.woff') format('woff'),
  url('@{fonts-path}hinted-OpenSans-CondensedLightItalic.ttf') format('truetype'),
  url('@{fonts-path}hinted-OpenSans-CondensedLightItalic.svg#OpenSans-CondensedLightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-BoldIt.eot');
  src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'),
  url('@{fonts-path}hinted-SourceSansPro-BoldIt.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-BoldIt.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-BoldIt.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-BoldIt.svg#SourceSansPro-BoldIt') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@{fonts-path}hinted-Montserrat-Regular.eot');
  src: local('Montserrat-Regular'),
  url('@{fonts-path}hinted-Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-Montserrat-Regular.woff') format('woff'),
  url('@{fonts-path}hinted-Montserrat-Regular.ttf') format('truetype'),
  url('@{fonts-path}hinted-Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('@{fonts-path}hinted-SourceSansPro-LightIt.eot');
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'),
  url('@{fonts-path}hinted-SourceSansPro-LightIt.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}hinted-SourceSansPro-LightIt.woff') format('woff'),
  url('@{fonts-path}hinted-SourceSansPro-LightIt.ttf') format('truetype'),
  url('@{fonts-path}hinted-SourceSansPro-LightIt.svg#SourceSansPro-LightIt') format('svg');
  font-weight: 300;
  font-style: italic;
}

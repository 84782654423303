#navbar {
  border: none;
  margin-bottom: @grid-gutter-width;

  .navbar-toggle {
    background-color: @color-transparent;
    border: none;
    bottom: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: 0;

    z-index: 1;

    &:hover {
      background-color: @color-transparent;
    }

    .icon-bar {
      .__transition(all 0.2s);
      background-color: @border-color-navigation-toggle;
      height: 8px;
      width: 46px;
    }

    .top-bar {
      .__transform(rotate(45deg));
      .__transform-origin(10%, 10%);
    }

    .middle-bar {
      .__opacity(0);
    }

    .bottom-bar {
      .__transform(rotate(-45deg));
      .__transform-origin(10%, 90%);
    }

    &.collapsed {
      .top-bar,
      .bottom-bar {
        .__transform(rotate(0));
      }

      .middle-bar {
        .__opacity(1);
      }

      .middle-bar,
      .bottom-bar {
        margin-top: 7px;
      }
    }
  }

  .navbar-header {
    float: left;
    position: relative;

    .logo {
      margin: 31px 0 0;
      padding: 0;

      img {
        .img-responsive;
      }
    }
  }

  .navbar-collapse {
    float: left;
    padding-left: 30px;
    padding-right: 0;
    width: 72%;

    // Redes sociales y buscador
    .navbar-search-box-social-networks {
      &:extend(.clearfix all);
      .text-right;
      margin-top: @grid-gutter-width;

      .navbar-search-box,
      .navbar-social-networks {
        display: inline-block;
        vertical-align: middle;
      }

      .navbar-search-box {
        width: 240px;

        .form-wrapper {
          margin-bottom: 0;
        }

        .input-group {
          border: 1px solid @input-border;

          .form-text,
          .input-group-btn .btn {
            border: none;
          }

          .form-text {
            .__box-shadow(none);
            height: 30px;
          }

          .input-group-btn .btn {
            background-color: @color-transparent;

            .glyphicon {
              color: @color-search;
            }
          }
        }
      }

      .navbar-social-networks {
        margin-left: 15px;
      }
    }

    // Menu
    nav {
      &:extend(.clearfix all);

      .menu.navbar-nav {
        margin-top: 16px;

        > li {
          .__transition(all .5s);
          position: relative;
          display: block;

          a {
            font-family: @font-family-source-sans-pro;
          }

          > a {
            .__transition(all .5s);
            padding: 0 7.25px;
            text-transform: uppercase;

            &.active-trail {
              color: @navbar-default-link-active-color;
              background-color: @navbar-default-link-active-bg;
            }

            .caret {
              .responsive-invisibility();
            }
          }

          .dropdown-menu {
            .__box-shadow(none);
            background-color: @navbar-default-link-hover-color;
            border: none;
            left: -40px;
            margin-top: 11.25px;
            padding: 0;
            right: -40px;

            > li {
              &:first-child {
                > a {
                  background: url('@{images-path}bg/bullet-submenu.png') no-repeat scroll center top @color-transparent;
                }
              }

              &:last-child {
                > a {
                  border-bottom: none;
                }
              }

              > a {
                border-bottom: 1px solid @color-submenu-border;
                color: @color-white;
                padding: 15px;

                &:hover,
                &:focus,
                &.active,
                &.active-trail {
                  background-color: @color-submenu-bg-active;
                }
              }

              &.dropdown-submenu {
                position: relative;

                a {
                  position: relative;

                  .caret {
                    display: none;
                  }
                }

                > .dropdown-menu {
                  top: 0;
                  left: 100%;
                  margin-top: 0;
                  margin-left: -1px;
                  right: auto;

                  > li {
                    &:first-child {
                      > a {
                        background-image: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    .navbar-header {
      display: table;
      margin: 0 auto;
      float: none;
    }

    .navbar-collapse {
      padding-left: 0;
      width: 100%;

      .navbar-search-box-social-networks {
        .text-center;
      }

      nav {
        .text-center;

        .menu.navbar-nav {
          display: inline-block;
          float: none;
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .navbar-collapse {
      nav {
        .menu.navbar-nav {
          .text-center;

          > li {
            display: inline-block;
            float: none;

            > .dropdown-menu {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: @screen-xs-max) {
    margin-bottom: 0;

    .navbar-header {
      float: none;

      .logo {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 15px;
        width: 74%;
      }
    }

    .navbar-collapse {
      padding-left: 0;
      float: none;
      width: auto;

      .navbar-search-box-social-networks {
        .responsive-invisibility();
      }

      nav {
        .menu.navbar-nav {
          background-color: @navbar-default-link-hover-color;
          margin: 0;

          > li {
            border-bottom: 1px solid @color-submenu-border;

            > a {
              color: @color-white;
              padding: 15px;
              text-transform: uppercase;

              &.active-trail {
                color: @color-white;
              }

              &:hover {
                color: darken(@color-white, 15%);
              }
            }

            .dropdown-menu {
              background-color: @color-submenu-bg-active;
              margin-top: 0;
              padding-left: 15px;
              padding-right: 15px;

              > li {
                > a {
                  border-bottom: none;

                  &:hover {
                    color: darken(@color-white, 15%);
                  }
                }

                &:first-child > a {
                  background-image: none;
                }

                &.dropdown-submenu.open > a {
                  border-bottom: 1px solid @color-submenu-border;
                }
              }
            }
          }

          li {
            > a .caret {
              .responsive-visibility();
              background: url('@{images-path}/icons/icon-chevron-right.png') no-repeat scroll 0 0 @color-transparent;
              border: none;
              height: 17px;
              margin-left: 0;
              position: absolute;
              right: 15px;
              top: 50%;
              margin-top: -8.5px;
              width: 9px;
            }

            &.open > a .caret {
              background: url('@{images-path}/icons/icon-chevron-down.png') no-repeat scroll 0 0 @color-transparent;
              height: 9px;
              margin-top: -4.5px;
              width: 17px;
            }
          }
        }
      }
    }
  }
}

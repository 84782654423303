/* Sticky footer styles
-------------------------------------------------- */
html, body {
  height: 100%;
}

.site-main {
  clear: both;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin-bottom: -@footer-sticky-min-height;
}

.footer-push {
  min-height: @footer-sticky-min-height;
}

.footer {
  clear: both;
  position: relative;

  /* Set the fixed height of the footer here */
  min-height: @footer-sticky-min-height;
  margin-top: 0;
}

/* Footer */
.footer {
  background: url('@{images-path}bg/border-footer.jpg') no-repeat scroll center top @bg-color-footer;
  padding-top: 50px;
  padding-bottom: 90px;

  .region-footer {
    &:extend(.row);
  }

  .block .block-title {
    color: @color-white;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  @media (max-width: @screen-xs-max) {

    .block {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .block-aanimada-frontend {
      .text-center;
    }
  }
}
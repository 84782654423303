//== Mixin Vendor
//
//##

.__background-image-linear-gradient(...) {
  /* autoprefixer: off */

  background-image: -webkit-linear-gradient(@arguments);
  background-image: -moz-linear-gradient(@arguments);
  background-image: -ms-linear-gradient(@arguments);
  background-image: -o-linear-gradient(@arguments);
  background-image: linear-gradient(@arguments);
}

.__text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}

.__box-shadow (@string) {
  /* autoprefixer: off */
  -webkit-box-shadow: @string;
  -moz-box-shadow:    @string;
  box-shadow:         @string;
}

.__drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  /* autoprefixer: off */
  -webkit-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:		@x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.__inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  /* autoprefixer: off */
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow:    inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow:         inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.__box-sizing (@type: border-box) {
  /* autoprefixer: off */
  -webkit-box-sizing: @type;
  -moz-box-sizing:    @type;
  box-sizing:         @type;
}

.__border-radius (@radius: 5px) {
  /* autoprefixer: off */
  -webkit-border-radius: @radius;
  -moz-border-radius:    @radius;
  border-radius:         @radius;
}

.__background-clip() {
  /* autoprefixer: off */
  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.__border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  /* autoprefixer: off */
  -webkit-border-top-right-radius:    @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius:  @bottomleft;
  -webkit-border-top-left-radius:     @topleft;

  -moz-border-radius-topright:        @topright;
  -moz-border-radius-bottomright:     @bottomright;
  -moz-border-radius-bottomleft:      @bottomleft;
  -moz-border-radius-topleft:         @topleft;

  border-top-right-radius:            @topright;
  border-bottom-right-radius:         @bottomright;
  border-bottom-left-radius:          @bottomleft;
  border-top-left-radius:             @topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

.__opacity (@opacity: 0.5) {
  /* autoprefixer: off */
  -webkit-opacity: 	@opacity;
  -moz-opacity: 	@opacity;
  opacity: 		    @opacity;
}

.__gradient (@startColor: #eee, @endColor: white) {
  /* autoprefixer: off */
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
}

.__animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
  /* autoprefixer: off */
  -webkit-animation: @name @duration @delay @ease;
  -moz-animation:    @name @duration @delay @ease;
  -ms-animation:     @name @duration @delay @ease;
}

.__transition (@transition) {
  /* autoprefixer: off */
  -webkit-transition: @transition;
  -moz-transition:    @transition;
  -ms-transition:     @transition;
  -o-transition:      @transition;
  transition:         @transition;
}

.__transition-duration(@duration: 0.2s) {
  /* autoprefixer: off */
  -moz-transition-duration: @duration;
  -webkit-transition-duration: @duration;
  -o-transition-duration: @duration;
  transition-duration: @duration;
}

.__transform(@string){
  /* autoprefixer: off */
  -webkit-transform: @string;
  -moz-transform: 	 @string;
  -ms-transform: 	 @string;
  -o-transform: 	 @string;
  transform: 	     @string;
}

.__scale (@factor) {
  /* autoprefixer: off */
  -webkit-transform: scale(@factor);
  -moz-transform: 	 scale(@factor);
  -ms-transform: 	 scale(@factor);
  -o-transform: 	 scale(@factor);
}

.__rotate (@deg) {
  /* autoprefixer: off */
  -webkit-transform: rotate(@deg);
  -moz-transform: 	 rotate(@deg);
  -ms-transform:     rotate(@deg);
  -o-transform:      rotate(@deg);
}

.__skew (@deg, @deg2) {
  /* autoprefixer: off */
  -webkit-transform:  skew(@deg, @deg2);
  -moz-transform: 	  skew(@deg, @deg2);
  -ms-transform: 	  skew(@deg, @deg2);
  -o-transform: 	  skew(@deg, @deg2);
}

.__translate (@x, @y:0) {
  /* autoprefixer: off */
  -webkit-transform:     translate(@x, @y);
  -moz-transform: 	     translate(@x, @y);
  -ms-transform: 		 translate(@x, @y);
  -o-transform: 		 translate(@x, @y);
}

.__translate3d (@x, @y: 0, @z: 0) {
  /* autoprefixer: off */
  -webkit-transform:  translate3d(@x, @y, @z);
  -moz-transform: 	  translate3d(@x, @y, @z);
  -ms-transform: 	  translate3d(@x, @y, @z);
  -o-transform: 	  translate3d(@x, @y, @z);
}

.__perspective (@value: 1000) {
  /* autoprefixer: off */
  -webkit-perspective: 	@value;
  -moz-perspective: 	@value;
  -ms-perspective: 	@value;
  perspective: 		@value;
}

.__transform-origin (@x:center, @y:center) {
  /* autoprefixer: off */
  -webkit-transform-origin: @x @y;
  -moz-transform-origin:    @x @y;
  -ms-transform-origin:     @x @y;
  -o-transform-origin:      @x @y;
  transform-origin:         @x @y;
}

//== Mixins Custom
//
//##

//
// Banner con Imagen
//
._banner() {
  position: relative;
  margin-bottom: 50px;

  .views-field {
    img {
      .img-responsive();
      margin: 0 auto;
      width: 100%;
    }

    a {
      &:after {
        .__background-image-linear-gradient(~'to right, rgba(255,255,255, 0.8) 40%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 100%');

        &:extend(.clearfix:after);
        bottom: 0;
        display: block;
        position: absolute;
        top: 0;
        width: 100%;

        @media (max-width: @screen-sm-max) {
          background-image: none;
          background-color: fade(@color-white, 80%);
        }

        @media (max-width: @screen-xs-max) {
          background-color: @bs-control-bg-color-mobile;
        }
      }
    }
  }

  .banner,
  .banner-slider {
    // Contenido
    &-content {
      .clearfix();

      position: absolute;
      top: 50%;
      margin-top: -170px;
      left: 0;
      right: 0;

      @media (max-width: @screen-sm-max) {
        margin-top: -80px;
        bottom: 50px;
      }
    }

    &-title,
    &-tag,
    &-description,
    &-view-more {
      margin-bottom: 15px;

      @media (max-width: @screen-sm-max) {
        .text-center;
      }
    }

    &-tag {
      color: @link-color;
      font-family: @font-family-montserrat;
      font-size: @font-size-h2;
      text-transform: uppercase;
    }

    &-title {
      text-transform: uppercase;

      @media (max-width: @screen-xs-max) {
        margin-bottom: @grid-gutter-width / 2;
      }

      h2 {
        font-size: @font-size-slide-title;
        line-height: 100%;
        margin: 0;

        @media (max-width: @screen-xs-max) {
          font-size: @font-size-h2;
        }

        a {
          color: @color-black;

          &:hover {
            color: lighten(@color-black, 15%);
          }
        }
      }
    }

    &-description {
      margin-bottom: @grid-gutter-width;
    }
  }
}

//
// Banner Slider
//
._banner-slider(@render-slider: true, @buttons-render: true, @pagination-render: true) when (@render-slider = true) {

  .owl-carousel {
    .owl-wrapper-outer {
      .owl-item {
        .text-left;
      }
    }

    .owl-controls {
      margin: 0;

      .owl-pagination {
        position: absolute;
        width: 100%;
        bottom: 2%;
        z-index: 1;

        @media (max-width: @screen-xs) {
          .responsive-invisibility();
        }

        .owl-page {
          span {
            background-color: @bs-control-color;
            height: 22px;
            width: 22px;
          }

          &.active {
            span {
              background-color: @bs-control-color-hover;
            }
          }
        }
      }

      .owl-buttons {

        & when (@buttons-render = false) {
          .responsive-invisibility();
        }

        @media (max-width: @screen-sm-max) {
          .responsive-invisibility();
        }

        .owl-prev,
        .owl-next {
          .text-hide;
          .__border-radius(0);
          .__opacity(0.3);

          background: url('@{images-path}/icons/icon-menu-left.png') no-repeat scroll 0 0 @color-transparent;
          height: 49px;
          margin: -24.5px 0 0;
          position: absolute;
          padding: 0;
          top: 50%;
          width: 29px;
        }

        .owl-prev {
          left: 0;
        }

        .owl-next {
          background-image: url('@{images-path}/icons/icon-menu-right.png');
          right: 0;
        }
      }
    }
  }
}


//
// Background Image Responsive
//
._background-image-responsive(@path, @attachment: fixed, @size: cover, @position: center center, @color: transparent) {
  background-image: url('@{path}');
  background-repeat: no-repeat;
  background-color: @color;
  background-position: @position;
  background-attachment: @attachment;
  background-size: @size;
}
